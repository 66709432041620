/* Estilos para el contenedor principal del Card */
.card-container {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0px 10px;
  }
  .card-container:hover  {
    border: 1px solid #7ab1e8;
    box-shadow: 0 2px 4px #7ab1e8;
}

  /* Estilos para el título del Card */
    .card-title {
    font-size: 28px;
    font-weight: 700;
    margin: 10px 0px;
    text-align: center;

}
  
  /* Estilos para los datos personales */
  .personal-info {
    margin-bottom: 20px;
  }
  
  .personal-info label {
    font-weight: bold;
  }
  
  .personal-info span {
    display: block;
    margin-top: 5px;
  }
  
  /* Estilos para la información de pago */
  .payment-info {
    margin-bottom: 20px;
  }
  
  .payment-info label {
    font-weight: bold;
  }
  
  .payment-info span {
    display: block;
    margin-top: 5px;
  }
  
  /* Estilos para el pie del Card */
  .card-footer {
    text-align: center;
  }
  
  .card-footer a {
    text-decoration: none;
    color: #007bff;
  }
  
  .card-footer a:hover {
    text-decoration: underline;
  }

/* Estilos para botones */
.card-button {
    background: #005CB8;
    border-color: #005CB8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius:20px;
}

.mr-10px {
    margin-right:10px;
}


/* Estilos para los datos del cliente Card */
.data.client {
    background-color:aquamarine;
    border-radius:5px;
    
}


.form-group {
    display: inline-block;
    margin-bottom:0rem;
}


.form-group-container {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr 1fr;
}


.form-check-label:hover {

    color:#005CB8;
    /*font-weight: bold;*/ 
    /*font-size: 15.23px;*/
   
}



.invalid-feedbackCheckBox {
    display: inline;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}