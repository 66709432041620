.alert-container {
    position: fixed;
    bottom: 0px;
    right: 0px; 
    z-index: 10000;
}

.alert-modal {
    margin-bottom: 0px;
}

.alert-message {
    max-height: 100px;
    overflow: auto;
    word-wrap: break-word;
}
/*.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-out;
}
*/