
/* ---------------------------------------------------
    TITULO HOME
----------------------------------------------------- */
.barra-azul {
    background-color: #005CB8;
    height: 100px; /* Ajusta la altura según necesites */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 0 60px;
}

.titulo {
    color: white;
    font-weight: bold;
    /*padding: 0 30px;*/
}


/* ---------------------------------------------------
    IMAGENES HOME
----------------------------------------------------- */

.image-container {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
}

.image {
    display: block;
    width: 100%;
    height: auto;
    transition: transform 0.3s ease, filter 0.3s ease;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 26px;
    font-weight: bold;
    opacity: 0;
    transition: 0.3s ease-in;
    pointer-events: none;
    filter: drop-shadow(4px 4px 8px #D65E28);
}

.image-container:hover .image {
    filter: brightness(20%);

}

.image:hover {
    transform: scale(1.3);
}

.image-container:hover .overlay {
    opacity: 1;
}
