.App {
    display: flex;
    width: 100%;
    align-items: stretch;
  }
  
  @import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
  
  body {
    font-family: "Poppins", sans-serif;
    background: #fafafa;
  }
  
  a,
  a:hover,
  a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
  }

h1, h2, h3, h4, h5 {
    color: #d85c2c;
}


.btn.btn-Dark.btn-sm {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: 2px solid #d85c2c;
    color: #d85c2c;
    background-color: white;
    border-radius: 20px;
}
.card-title, .modal-title {
    color: #d85c2c !important;
}
/* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */
.sidebar {
    min-width: 250px !important;
    max-width: 250px !important;
    background: #fff !important;
    color: #d65e28 !important;
    margin-left: -250px;
    transition: all 0.5s;
}
  
  .sidebar.is-open {
    margin-left: 0;
    transition: 0.5s;
  }
  
  .sidebar-header {
    background: #fff;
  }
  
  .sidebar-header h3 {
    color: #fff;
    padding: 1em;
  }
  
  .sidebar ul p {
    color: #fff;
    padding: 10px;
  }
  
  .menu-open {
    background: #fff !important;
  }

.nav-item:hover {
    color: #d65e28 !important;
    background: #fff !important;
}

.items-menu {
    color: #d65e28 !important;
    background: #fff !important;
}
  
  li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 35% !important; 
    top: 10%;
  }
  
  .sidebar-header > span {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
  }
  .side-menu {
    height: calc(100vh - 130px);
    overflow-y: scroll;
  }
  
  .side-menu::-webkit-scrollbar {
    width: 10px;
  }
  
  .side-menu::-webkit-scrollbar-thumb {
    background: #fff !important;
    border-radius: 3px;
  }
  
  .side-menu::-webkit-scrollbar-thumb:hover {
    background: #fff !important;
  }
  
 /* ---------------------------------------------------
      Boton de SideBar
  ----------------------------------------------------- */
  .btn-info {
    background-color: orangered !important;
    border-color: orangered !important;
  }

  .btn-info:hover {
    color: #fff !important;
    background: orangered !important;
    border-color: orangered !important;
  }
  .btn-info.focus, .btn-info:focus {
    color: #fff !important;
    background: orangered !important;
    border-color: orangered !important;
    box-shadow: none !important;
  }

.navbar {
    background: #d65e28 !important;
}

  /* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */
  .content {
    padding: 20px;
    margin-left: 0;
    height: 100vh;
  }
  
  @media only screen and (max-width: 500px) {
    body {
      overflow: hidden;
    }
  
    .content.is-open {
      margin-left: 100%;
    }
  
    .sidebar.is-open {
      min-width: 100%;
      max-width: 100%;
      margin-left: 0;
      transition: all 0.5s, height 0s;
    }
  
    .sidebar.is-open > .sidebar-header span {
      display: unset;
    }
  
    li a.dropdown-toggle::after {
      display: inline-block;
      position: relative;
      left: 68%;
    }
  }

#logout {
    background-color: #ffffff !important;
    color: #d65e28 !important;
}

  .input-form-hook {    
    width: 35rem !important;    
  }
  
  .form-form-productos {    
    display: flex;
    flex-direction: column;
    align-items: start;
    align-content: space-around;    
  }
  





  figure {
    position: relative;
    width: 300px;
    height: 200px;
    overflow: hidden;
  }

  figure:hover img {
    filter: grayscale(100%);
  }

  figure:hover figcaption {
    background-color: #f1f1f1;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    transition: filter 0.3s ease;
  }

  figcaption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: #860808;
    opacity: 0;
    transition: background-color 0.3s ease, opacity 0.3s ease;
  }

  figure:hover figcaption {
    opacity: 1;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .button {
    padding: 10px 20px;
    background-color: #860808;
    color: #000000;
    text-decoration: none;
    border: 1px solid #000000;
    transition: background-color 0.3s ease;
  }

  figure:hover .button {
    background-color: #000000;
    color: #860808;
  }

