

body {
    margin: 0;
    margin-bottom: 12rem;
}

.grid {
    display: grid;    
    padding-left: 10rem;
    
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.img-wrapper {
    position: relative;
    overflow: hidden;
    /* width: 600px; */
    
}

.img-wrapper > img {
    display: block;
    width: 100%;
    aspect-ratio: 1 / 1 !important;  
    object-fit: cover;
    object-position: center;
}

.img-wrapper > .content {
    position: absolute;
    inset: 0;
    font-size: 2rem;
    padding: 1rem;
    background: rgba(255, 255, 255, .4);
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    color: #FFFF;
    font-weight: bold;
    flex-direction: column;
}


.img-wrapper > img,
.img-wrapper > .content {
    transition: 200ms ease-in-out;
}

.img-wrapper:hover > img.blur {
    filter: blur(5px);
}
.img-wrapper:hover > img.zoom {
    transform: scale(1.5);
}


.img-wrapper > .content.fade {
    opacity: 0;
}
.img-wrapper:hover > .content.fade {
    opacity: 1;
}


.img-wrapper > .content.slide-left {
    transform: translateX(-100%);
}
.img-wrapper:hover > .content.slide-left {
    transform: translateX(0);
}
.img-wrapper > .content.slide-right {
    transform: translateX(100%);
}
.img-wrapper:hover > .content.slide-right {
    transform: translateX(0);
}

.custom-text {
    color: #FFFF; 
    font-size: 24px; 
  }