/* Estilos para el contenedor principal del Card */
.card-container {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0px 10px;
    

}

    .card-container:hover {
        border: 1px solid #7ab1e8;
        box-shadow: 0 2px 4px #7ab1e8;
    }

/* Estilos para el título del Card */
.card-title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 10px 0px;
    text-align: center;
}

/* Estilos para los datos personales */
.personal-info {
    margin-bottom: 20px;
}

    .personal-info label {
        font-weight: bold;
    }

    .personal-info span {
        display: block;
        margin-top: 5px;
    }

/* Estilos para la información de pago */
/*.payment-info {
    margin-bottom: 20px;
}

    .payment-info label {
        font-weight: bold;
    }

    .payment-info span {
        display: block;
        margin-top: 5px;
    }*/

/* Estilos para el pie del Card */
.card-footer {
    text-align: center;
}

    .card-footer a {
        text-decoration: none;
        color: #007bff;
    }

        .card-footer a:hover {
            text-decoration: underline;
        }


/* Estilos para botones */
.card-button {
    background: #005CB8;
    border-color: #005CB8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 20px;
    font-size: 18px;
    height: 36px;
}

.danger-button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 20px;
    font-size: 18px;
    height: 36px;
}


.mr-10px {
    margin-right: 50px;
}


.EstiloCheck input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.EstiloCheck label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 30px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
}

    .EstiloCheck label:after {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
    }

.EstiloCheck input:checked + label {
    background: #005cb8;
}

.EstiloCheck input:checked + label:after {
    left: calc(95% - 5px);
    transform: translateX(-90%);
}

.EstiloCheck label:active:after {
    width: 100px;
}
/*Ajustamos el tamano del campo descuento y seguro*/
#CampoDescuento, #CampoSeguro {
    width: 70%;
}
#Div_Seguro {
    display: flex;
    margin-bottom: 1.5rem;
}

    #Div_Seguro div {
        position: relative;
        left: 10px;
        bottom: 27px;
    }